import { createTheme, Theme } from '@mui/material'
import { amber, red } from '@mui/material/colors'

export const maxevTheme = (theme: 'dark' | 'light'): Theme => {
  return createTheme({
    palette: {
      mode: theme,
      primary: {
        main: '#fb8733',
        contrastText: '#fff',
        dark: '#e1803a',
        light: '#f6842e',
      },
      secondary: {
        main: '#4f7b6f',
      },
      error: {
        main: red[500],
        dark: red[300],
        light: red[700],
      },
      warning: {
        main: amber[500],
        dark: amber[300],
        light: amber[700],
      },
    },
    typography: {
      h1: {
        fontSize: 28,
      },
      h2: {
        fontSize: 24,
      },
      h3: {
        fontSize: 22,
      },
      h4: {
        fontSize: 16,
      },
      h5: {
        fontSize: 16,
      },
      h6: {
        fontSize: 16,
      },
      body1: {
        fontSize: 14,
      },
      body2: {
        fontSize: 12,
      },
      caption: {
        fontSize: 10,
      },
    },
    components: {
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            display: 'flex',
          },
          titleText: {
            textAlign: 'left',
          },
          actions: {
            textAlign: 'right',
          },
          filterPaper: {
            maxWidth: '90%',
          },
        },
      },
      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            maxWidth: 500,
          },
          gridListTile: {
            '&&': {
              paddingTop: '16px',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '2px 16px',
          },
        },
      },
    },
  })
}
