import { createFilterDialogFooter } from '@/components/Tables/filters/createFilterDialogFooter.tsx'
import { MUIDataTableProps } from 'mui-datatables'

export const useTableDefaultOptions = () => {
  return {
    options: {
      filter: false,
      sort: false,
      selectableRows: 'none',
      serverSide: true,
      responsive: 'standard',
      confirmFilters: true,
      customFilterDialogFooter: createFilterDialogFooter,
      download: false,
      print: false,
      viewColumns: false,
      search: false,
      resizableColumns: false,
      enableNestedDataAccess: '.',
      filterType: 'textField',
      expandableRows: false,
    } as MUIDataTableProps['options'],
    components: {
      TableFilterList: () => null,
    } as MUIDataTableProps['components'],
  }
}
