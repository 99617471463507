import { useState } from 'react'

import { CurrencyRateTable } from '@/components/Containers/CurrencyRateTable/CurrencyRateTable.tsx'
import { WithAlert } from '@/components/Utils/WithAlert/WithAlert.tsx'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader.tsx'
import { useGetTodayRate } from '@/services/hooks/currency-rate/useGetTodayRate.ts'
import { RoomCurrency } from '@/types.ts'
import { CurrencyHelper } from '@/utils/CurrencyHelper.ts'
import {
  Box,
  Popper,
  Typography,
  ClickAwayListener,
  Paper,
  useMediaQuery,
  Theme,
} from '@mui/material'

interface CurrencyRateProps {
  from: RoomCurrency
  to: RoomCurrency
  view?: 'full' | 'sign-only'
}

export const CurrencyRate = ({
  from,
  to,
  view = 'full',
}: CurrencyRateProps) => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const {
    data: rate,
    isFetching: isRateFetching,
    isError: isRateError,
    refetch: refetchRate,
  } = useGetTodayRate({ fromCurrency: from, toCurrency: to })

  const currencyView = {
    from: view === 'full' ? from : CurrencyHelper.getCurrencySign(from),
    to: view === 'full' ? to : CurrencyHelper.getCurrencySign(to),
  }

  const currencyPair = `${currencyView.from}/${currencyView.to}`
  const currencyRate = (rate || 0).toFixed(4)

  return (
    <Box
      sx={{
        paddingX: '24px',
      }}
    >
      <WithLoader isLoading={isRateFetching} size={1}>
        <WithAlert
          isRestricted={isRateError}
          message="Не удалось загрузить курс"
          onRefresh={() => void refetchRate()}
        >
          {rate && (
            <ClickAwayListener
              onClickAway={() => {
                setAnchorEl(null)
                setOpen(false)
              }}
            >
              <Box>
                <Typography
                  aria-describedby="currency-rate-popper"
                  role="button"
                  sx={{ cursor: 'pointer' }}
                  onClick={event => {
                    setOpen(true)
                    setAnchorEl(event.currentTarget.parentElement)
                  }}
                >
                  {currencyPair}: {currencyRate}
                </Typography>
                <Popper
                  id="currency-rate-popper"
                  open={open}
                  placement="bottom-start"
                  anchorEl={anchorEl}
                >
                  <Paper>
                    <Box
                      p={0}
                      minWidth={md ? 350 : 500}
                      maxWidth={md ? 350 : 500}
                    >
                      <CurrencyRateTable from={from} to={to} view={view} />
                    </Box>
                  </Paper>
                </Popper>
              </Box>
            </ClickAwayListener>
          )}
        </WithAlert>
      </WithLoader>
    </Box>
  )
}
