import { memo } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { CUSTOM_SETTING_WIKI_URL } from '@/components/CustomSettings/constants'
import { useGetCustomSettingByName } from '@/services/hooks/custom-settings/useGetCustomSettingByName'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import { Button } from '@mui/material'

import { NavigationItem } from './DashboardNavigation'

const DashboardMenu = ({ routes }: { routes: NavigationItem[] }) => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUser()
  const { data: wikiInfo } = useGetCustomSettingByName({
    name: CUSTOM_SETTING_WIKI_URL,
  })

  if (!currentUser) {
    return null
  }

  return (
    <>
      {routes.map(({ key, label, icon, route }: NavigationItem) => {
        const href = typeof route === 'function' ? route(currentUser) : route

        return (
          <Button
            component={Link}
            to={href}
            onClick={() => {
              navigate(href)
            }}
            key={key}
            variant="text"
            startIcon={icon}
            sx={{ color: 'text.primary', cursor: 'pointer' }}
          >
            {label}
          </Button>
        )
      })}
      {wikiInfo && (
        <Button
          onClick={() => {
            window.open(
              // @ts-ignore
              wikiInfo?.value?.content,
              '_blank', // <- This is what makes it open in a new window.
            )
          }}
          key={CUSTOM_SETTING_WIKI_URL}
          variant="text"
          startIcon={<LiveHelpIcon />}
          sx={{ color: 'text.primary', cursor: 'pointer' }}
        >
          {/* @ts-ignore */}
          {wikiInfo?.value?.title as string}
        </Button>
      )}
    </>
  )
}

export default memo(DashboardMenu)
