import { useState } from 'react'

import { CurrencyRateTableCalculationCell } from '@/components/Containers/CurrencyRateTable/CurrencyRateTableCalculationCell.tsx'
import { TableDate } from '@/components/Tables/columns/TableDate/TableDate.tsx'
import TableContainer from '@/components/Tables/components/TableContainer'
import { createRangeFilter } from '@/components/Tables/filters/createRangeFilter.tsx'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels.tsx'
import { WithAlert } from '@/components/Utils/WithAlert/WithAlert.tsx'
import { CurrencyRateControllerHistoryRatesParams } from '@/services/data-contracts.ts'
import { useGetHistoryRates } from '@/services/hooks/currency-rate/useGetHistoryRates.ts'
import { RoomCurrency } from '@/types.ts'
import { CurrencyHelper } from '@/utils/CurrencyHelper.ts'
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables'

export interface CurrencyRateTableProps {
  from: RoomCurrency
  to: RoomCurrency
  view: 'full' | 'sign-only'
}

const FILTERS_DEFAULT: Partial<CurrencyRateControllerHistoryRatesParams> = {}

export const CurrencyRateTable = ({
  from,
  to,
  view = 'full',
}: CurrencyRateTableProps) => {
  const tableDefault = useTableDefaultOptions()
  const tablePagination = useTablePagination()

  const [filters, setFilters] =
    useState<Partial<CurrencyRateControllerHistoryRatesParams>>(FILTERS_DEFAULT)

  const handleFilterConfirm: MUIDataTableOptions['onFilterConfirm'] =
    filterList => {
      // eslint-disable-next-line prefer-destructuring
      const date = filterList?.[0]?.[0]

      tablePagination.helpers.resetPage()
      setFilters({
        ...(date && {
          date: String(date),
        }),
      })
    }

  const {
    data: response,
    isFetching,
    isError,
    refetch,
  } = useGetHistoryRates({
    ...filters,
    ...tablePagination.queryParams,
    fromCurrency: from,
    toCurrency: to,
    sort: 'date:DESC',
  })

  const currencyView = {
    from: view === 'full' ? from : CurrencyHelper.getCurrencySign(from),
    to: view === 'full' ? to : CurrencyHelper.getCurrencySign(to),
  }

  const currencyPair = `${currencyView.from}/${currencyView.to}`

  const [data, count] = response ? [response?.data, response?.count] : [[], 0]

  return (
    <WithAlert
      isRestricted={isError}
      message={`Не удалось загрузить историю курсов для ${currencyPair}`}
      onRefresh={refetch}
    >
      <TableContainer>
        <MUIDataTable
          title={`Курсы валют для ${currencyPair}`}
          columns={[
            {
              name: 'date',
              label: 'Дата',
              options: {
                filter: true,
                filterType: 'custom',
                filterOptions: createRangeFilter({
                  type: 'date',
                  showSingleFilter: true,
                }),
                customBodyRender: value => {
                  return TableDate(value)
                },
              },
            },
            {
              name: 'rate',
              label: 'Курс',
              options: {
                filter: false,
                customBodyRender: (rate: number) => {
                  return rate.toFixed(4)
                },
              },
            },
            {
              name: '__derived__rate__',
              label: 'Посчитать',
              options: {
                filter: false,
                customBodyRender: (_, tableMeta) => {
                  const row = data[tableMeta.rowIndex]
                  return (
                    <CurrencyRateTableCalculationCell
                      fromCurrency={from}
                      toCurrency={to}
                      currencyRate={row.rate}
                      view={view}
                    />
                  )
                },
              },
            },
          ]}
          data={data}
          options={{
            ...tableDefault.options,
            ...tablePagination.options,
            count,
            tableBodyHeight: '300px',
            rowsPerPageOptions: [],
            filter: true,
            onFilterConfirm: handleFilterConfirm,
            textLabels: createTextLabels({
              isLoading: isFetching,
              textLabels: {
                body: {
                  noMatch: 'Курсов не найдено',
                },
              },
            }),
          }}
          components={tableDefault.components}
        />
      </TableContainer>
    </WithAlert>
  )
}
