import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  ConfirmModal,
  ConfirmModalProps,
} from '@/components/Modals/ConfirmModal/ConfirmModal'
import { UserGoogleSheetsSettingsModal } from '@/components/Modals/UserGoogleSheetsSettingsModal/UserGoogleSheetsSettingsModal'
import { UserModal } from '@/components/Modals/UserModal/UserModal'
import { UserPersonalDataModal } from '@/components/Modals/UserPersonalDataModal/UserPersonalDataModal'
import { UserRoomSettings } from '@/components/Modals/UserRoomSettingsModal/UserRoomSettings'
import { TableCreatedAt } from '@/components/Tables/columns/TableCreatedAt/TableCreatedAt'
import { TableUser } from '@/components/Tables/columns/TableUser/TableUser'
import TableContainer from '@/components/Tables/components/TableContainer'
import { useMenu } from '@/components/Tables/hooks/useMenu'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'
import { useTableSort } from '@/components/Tables/hooks/useTableSort.ts'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels'
import { AddEntityButton } from '@/components/Tables/toolbar/AddEntityButton'
import { UserRoleMap, UserRoleValue } from '@/constants'
import { useSnackBar } from '@/context/SnackbarContext'
import { UsersRo } from '@/services/data-contracts'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { useDeleteUser } from '@/services/hooks/users/useDeleteUser'
import { useGetUsers } from '@/services/hooks/users/useGetUsers'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import DeleteIcon from '@mui/icons-material/Delete'
import DescriptionIcon from '@mui/icons-material/Description'
import EditIcon from '@mui/icons-material/Edit'
import GoogleIcon from '@mui/icons-material/Google'
import SettingsIcon from '@mui/icons-material/Settings'
import { FormControlLabel, Switch } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { ShowFnOutput, useModal } from 'mui-modal-provider'

const LIMIT_DEFAULT = 500

export const UsersTable = () => {
  const tableSort = useTableSort(['username', 'ASC'])
  const tablePagination = useTablePagination({ limit: LIMIT_DEFAULT })
  const tableDefault = useTableDefaultOptions()
  const [showKicked, setShowKicked] = useState(true)
  const { showSnackBar } = useSnackBar()
  const { showModal } = useModal()
  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()

  const deleteUser = useDeleteUser({
    onSuccess: () => showSnackBar('Пользователь успешно удален', 'success'),
    onError: () => showSnackBar('Ошибка при удалении пользователя', 'error'),
  })

  const { data: users, isLoading: isUsersFetching } = useGetUsers({
    ...tablePagination.queryParams,
    ...tableSort.queryParams,
    ...(!showKicked ? { 'isKicked.equal': showKicked } : undefined),
  })

  const [data, count] = users ? [users.data, users.count] : [[], 0]

  const handleOpenUserCreateModal = () => {
    const modal = showModal(UserModal, {
      mode: 'create',
      userId: undefined,
      onSuccess: () => {
        modal.hide()
        showSnackBar('Пользователь успешно создан', 'success')
      },
      onError: err => {
        showSnackBar('Ошибка при создании пользователя', 'error', err)
      },
    })
  }

  const handleOpenUserEditModal = (row: UsersRo) => {
    const modal = showModal(UserModal, {
      mode: 'edit',
      userId: row.id,
      onSuccess: () => {
        modal.hide()
        showSnackBar('Пользователь успешно отредактирован', 'success')
      },
      onError: err => {
        showSnackBar('Ошибка при редактировании пользователя', 'error', err)
      },
    })
  }

  const handleOpenUserDeleteModal = (row: UsersRo) => {
    const modal: ShowFnOutput<ConfirmModalProps> = showModal(ConfirmModal, {
      headerText: `Вы действительно хотите удалить пользователя "${row.username}"?`,
      buttons: {
        confirm: {
          onClick: () => {
            deleteUser.mutate({ id: row.id })
            modal.hide()
          },
          children: 'Удалить',
        },
        cancel: {
          onClick: () => modal.hide(),
        },
      },
    })
  }

  const handleOpenUserGoogleSheetsSettingsModal = (row: UsersRo) => {
    showModal(UserGoogleSheetsSettingsModal, {
      userId: row.id,
    })
  }

  const handleOpenUserRoomSettingsModal = (row: UsersRo) => {
    showModal(UserRoomSettings, { userId: row.id })
  }

  const handleOpenUserPersonalDataModal = (row: UsersRo) => {
    const modal = showModal(UserPersonalDataModal, {
      userId: row.id,
      onSuccess: () => {
        modal.hide()
        showSnackBar('Личные данные пользователя успешно обновлены', 'success')
      },
      onError: err => {
        showSnackBar(
          'Ошибка при обновлении личных данных пользователя',
          'error',
          err,
        )
      },
    })
  }

  const handleOpenUserReports = (row: UsersRo) => {
    navigate(`/cabinet/user-reports/${row.id}`)
  }

  const handleOpenCoachUserReviews = (row: UsersRo) => {
    navigate(`/cabinet/reviews/user/${row.id}`)
  }

  const { TableMenuColumn } = useMenu<UsersRo>({
    data,
    options: [
      {
        id: 'edit',
        label: 'Редактировать',
        onClick: handleOpenUserEditModal,
        icon: <EditIcon color="secondary" />,
      },
      {
        id: 'personal-data',
        label: 'Личная информация',
        onClick: handleOpenUserPersonalDataModal,
        icon: <ContactPhoneIcon color="secondary" />,
      },
      {
        id: 'rooms-settings',
        label: 'Настройки румов',
        onClick: handleOpenUserRoomSettingsModal,
        icon: <SettingsIcon color="primary" />,
      },
      {
        id: 'user-reports',
        label: 'Отчёты игрока',
        onClick: handleOpenUserReports,
        icon: <DescriptionIcon color="action" />,
      },
      {
        id: 'coach-user-reviews',
        label: 'Отзывы тренеров об игроке',
        onClick: handleOpenCoachUserReviews,
        icon: <AssignmentIndIcon color="action" />,
      },
      {
        id: 'google-sheets-settings',
        label: 'Google sheets',
        onClick: handleOpenUserGoogleSheetsSettingsModal,
        icon: <GoogleIcon color="secondary" />,
      },
      {
        id: 'delete',
        label: 'Удалить',
        onClick: handleOpenUserDeleteModal,
        isDisabled: row => row.id === currentUser?.id,
        icon: <DeleteIcon color="error" />,
      },
    ],
  })

  return (
    <TableContainer>
      <MUIDataTable
        title="Пользователи"
        data={data}
        columns={[
          {
            name: 'id',
            label: 'ID',
          },
          {
            name: 'createdAt',
            label: 'Создан',
            options: {
              customBodyRender: TableCreatedAt,
            },
          },
          {
            name: 'username',
            label: 'Никнейм',
            options: {
              customBodyRender: TableUser,
            },
          },
          {
            name: 'email',
            label: 'E-mail',
          },
          {
            name: 'role',
            label: 'Роль',
            options: {
              customBodyRender: (value: UserRoleValue) =>
                UserRoleMap[value] || value,
            },
          },
          {
            name: 'rank',
            label: 'Ранг',
          },
          {
            name: 'isKicked',
            label: 'Активность',
            options: {
              customBodyRender: (value: boolean) =>
                value ? 'Кикнут' : 'Активен',
            },
          },
          {
            name: 'contract.name',
            label: 'Пакет',
          },
          {
            name: '__actions__',
            label: ' ',
            options: {
              customBodyRenderLite: TableMenuColumn,
            },
          },
        ]}
        options={{
          ...tableDefault.options,
          ...tablePagination.options,
          ...tableSort.options,
          count,
          tableBodyHeight: '700px',
          textLabels: createTextLabels({
            isLoading: isUsersFetching,
            textLabels: {
              body: {
                noMatch: 'Пользователей не найдено',
              },
            },
          }),
          customToolbar: () => (
            <>
              <FormControlLabel
                control={<Switch />}
                checked={showKicked}
                onChange={() => {
                  setShowKicked(prev => !prev)
                }}
                label="Показать кикнутых"
              />
              <AddEntityButton onClick={handleOpenUserCreateModal} />
            </>
          ),
        }}
        components={tableDefault.components}
      />
    </TableContainer>
  )
}
