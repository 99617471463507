import { memo, useMemo, useState } from 'react'

import { useGetSort } from '@/components/Containers/RoomsTable/useGetSort.ts'
import RoomCell from '@/components/Containers/UsersRoomsSettingsTable/RoomCell.tsx'
import RoomHeadCell from '@/components/Containers/UsersRoomsSettingsTable/RoomHeadCell.tsx'
import { TableImageTitle } from '@/components/Tables/columns/TableImageTitle/TableImageTitle.tsx'
import TableContainer from '@/components/Tables/components/TableContainer'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'
import { useTableSort } from '@/components/Tables/hooks/useTableSort.ts'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels.tsx'
import { useTheme } from '@/context/ThemeContext.tsx'
import { useGetPlayersRoomsSettings } from '@/services/hooks/cabinet/player/useGetPlayersRoomsSettings.ts'
import { useGetRooms } from '@/services/hooks/rooms/useGetRooms.ts'
import { FormControlLabel, Switch } from '@mui/material'
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables'

const LIMIT_PLAYERS = 500
const LIMIT_ROOMS = 500
const UsersRoomsSettingsTable = () => {
  const { defaultSortString } = useGetSort()
  const { theme } = useTheme()
  const background = theme === 'light' ? 'white' : '#121212'
  const borderLeft =
    theme === 'light'
      ? '1px solid rgba(224, 224, 224, 1)'
      : '1px solid rgba(81, 81, 81, 1)'
  const tableSort = useTableSort(['username', 'ASC'])
  const tablePagination = useTablePagination({ limit: LIMIT_PLAYERS })
  const tableDefault = useTableDefaultOptions()
  const [showKicked, setShowKicked] = useState(true)
  const { data: roomsData, isLoading: isRoomsLoading } = useGetRooms({
    limit: LIMIT_ROOMS,
    offset: 0,
    sort: defaultSortString,
  })

  const { data: users, isLoading: isUsersFetching } =
    useGetPlayersRoomsSettings({
      ...tablePagination.queryParams,
      ...tableSort.queryParams,
      ...(!showKicked ? { 'isKicked.equal': showKicked } : undefined),
    })

  const [data, count] = users ? [users.data, users.count] : [[], 0]

  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'id',
        label: 'ID',
        options: {
          setCellProps: () => ({
            style: {
              whiteSpace: 'wrap',
              position: 'sticky',
              left: 0,
              background,
              zIndex: 101,
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'wrap',
              position: 'sticky',
              left: 0,
              background,
              zIndex: 102,
            },
          }),
          customHeadLabelRender: value => {
            return (
              <TableImageTitle
                disableAvatar
                disableTooltip
                title={value.label ?? ''}
              />
            )
          },
        },
      },
      {
        name: 'username',
        label: 'Имя пользователя',
        options: {
          setCellProps: () => ({
            style: {
              width: `150px`,
              whiteSpace: 'wrap',
              position: 'sticky',
              left: '60px',
              background,
              zIndex: 101,
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              width: `150px`,
              whiteSpace: 'wrap',
              position: 'sticky',
              left: '60px',
              background,
              zIndex: 102,
            },
          }),
          customHeadLabelRender: value => {
            return (
              <TableImageTitle
                disableAvatar
                disableTooltip
                title={value.label ?? ''}
              />
            )
          },
        },
      },
      ...(roomsData?.data ?? []).map(room => ({
        name: `rooms.${room.id}`,
        label: room.name,
        options: {
          setCellProps: () => ({
            style: {
              borderLeft,
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              borderLeft,
            },
          }),
          filter: false,
          sort: false,
          customHeadLabelRender: () => {
            return (
              <RoomHeadCell
                name={room.name}
                location={room?.avatar?.location}
              />
            )
          },
          customBodyRenderLite: (dataIndex: number) => {
            const row = data[dataIndex]
            return <RoomCell row={row} roomInfo={room} />
          },
        },
      })),
    ],
    [background, borderLeft, data, roomsData?.data],
  )

  return (
    <TableContainer>
      <MUIDataTable
        columns={columns}
        title="Пользователи и румы"
        data={data}
        options={{
          ...tableDefault.options,
          ...tablePagination.options,
          ...tableSort.options,
          count,
          tableBodyHeight: '700px',
          textLabels: createTextLabels({
            isLoading: isUsersFetching || isRoomsLoading,
            textLabels: {
              body: {
                noMatch: 'Пользователей не найдено',
              },
            },
          }),
          customToolbar: () => (
            <>
              <FormControlLabel
                control={<Switch />}
                checked={showKicked}
                onChange={() => {
                  setShowKicked(prev => !prev)
                }}
                label="Показать кикнутых"
              />
            </>
          ),
        }}
      />
    </TableContainer>
  )
}

export default memo(UsersRoomsSettingsTable)
