import {
  ConfirmModal,
  ConfirmModalProps,
} from '@/components/Modals/ConfirmModal/ConfirmModal'
import { UserContractModal } from '@/components/Modals/UserContractModal/UserContractModal'
import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { TableCreatedAt } from '@/components/Tables/columns/TableCreatedAt/TableCreatedAt'
import { TablePercent } from '@/components/Tables/columns/TablePercent/TablePercent'
import TableContainer from '@/components/Tables/components/TableContainer'
import { useMenu } from '@/components/Tables/hooks/useMenu'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'
import { useTableSort } from '@/components/Tables/hooks/useTableSort.ts'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels'
import { AddEntityButton } from '@/components/Tables/toolbar/AddEntityButton'
import { useSnackBar } from '@/context/SnackbarContext'
import { UserContractSingle } from '@/services/data-contracts'
import { useDeleteUserContract } from '@/services/hooks/user-contracts/useDeleteUserContract'
import { useGetUserContracts } from '@/services/hooks/user-contracts/useGetUserContracts'
import { Typography } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { ShowFnOutput, useModal } from 'mui-modal-provider'

export const UserContractsTable = () => {
  const tableDefault = useTableDefaultOptions()
  const tablePagination = useTablePagination()
  const tableSort = useTableSort(['id', 'ASC'])

  const { showModal } = useModal()
  const { showSnackBar } = useSnackBar()

  const { data: contracts, isLoading } = useGetUserContracts({
    ...tablePagination.queryParams,
    ...tableSort.queryParams,
  })

  const deleteUserContract = useDeleteUserContract({
    onSuccess: () => showSnackBar('Пакет успешно удален', 'success'),
    onError: err => showSnackBar('Ошибка при удалении пакета', 'error', err),
  })

  const handleOpenUserContractCreateModal = () => {
    const modal = showModal(UserContractModal, {
      mode: 'create',
      userContractId: undefined,
      onSuccess: () => {
        showSnackBar('Пакет успешно создан', 'success')
        modal.hide()
      },
      onError: err => {
        showSnackBar('Ошибка при создании пакета', 'error', err)
      },
    })
  }

  const handleOpenUserContractEditModal = (row: UserContractSingle) => {
    const modal = showModal(UserContractModal, {
      mode: 'edit',
      userContractId: row.id,
      onSuccess: () => {
        showSnackBar('Пакет успешно отредактирован', 'success')
        modal.hide()
      },
      onError: err => {
        showSnackBar('Ошибка при редактировании пакета', 'error', err)
      },
    })
  }

  const handleDeleteUserContract = (row: UserContractSingle) => {
    const modal: ShowFnOutput<ConfirmModalProps> = showModal(ConfirmModal, {
      headerText: `Вы действительно хотите удалить пакет "${row.name}"?`,
      buttons: {
        confirm: {
          onClick: () => {
            deleteUserContract.mutate({ id: row.id })
            modal.hide()
          },
          children: 'Удалить',
        },
        cancel: {
          onClick: () => modal.hide(),
        },
      },
    })
  }

  const [data, count] = contracts ? [contracts.data, contracts.count] : [[], 0]

  const { TableMenuColumn } = useMenu<UserContractSingle>({
    data,
    options: [
      {
        id: 'edit',
        label: 'Редактировать',
        onClick: handleOpenUserContractEditModal,
      },
      { id: 'delete', label: 'Удалить', onClick: handleDeleteUserContract },
    ],
  })

  return (
    <TableContainer>
      <MUIDataTable
        title="Пакеты игроков"
        columns={[
          {
            name: 'id',
            label: 'ID',
            options: {
              customBodyRender: (value: string) => (
                <TableColumn width={20}>{value}</TableColumn>
              ),
            },
          },
          {
            name: 'createdAt',
            label: 'Создан',
            options: {
              customBodyRender: TableCreatedAt,
            },
          },
          {
            name: 'updatedAt',
            label: 'Обновлен',
            options: {
              customBodyRender: TableCreatedAt,
            },
          },
          {
            name: 'name',
            label: 'Название',
            options: {
              customBodyRender: (value: string) => (
                <TableColumn width={120}>
                  <Typography variant="body2">{value}</Typography>
                </TableColumn>
              ),
            },
          },
          {
            name: 'financialInterest',
            label: 'Процент финансирования',
            options: {
              customBodyRender: TablePercent,
            },
          },
          {
            name: 'playerShare',
            label: 'Доля игрока',
            options: {
              customBodyRender: TablePercent,
            },
          },
          {
            name: 'projectShare',
            label: 'Доля проекта',
            options: {
              customBodyRender: TablePercent,
            },
          },
          {
            name: '__actions__',
            label: ' ',
            options: {
              customBodyRenderLite: TableMenuColumn,
            },
          },
        ]}
        data={data}
        options={{
          ...tableDefault.options,
          ...tablePagination.options,
          ...tableSort.options,
          count,
          tableBodyHeight: '700px',
          textLabels: createTextLabels({
            isLoading: isLoading,
            textLabels: {
              body: {
                noMatch: 'Пакетов не найдено',
              },
            },
          }),
          customToolbar: () => (
            <AddEntityButton onClick={handleOpenUserContractCreateModal} />
          ),
        }}
        components={{
          TableFilterList: () => null,
        }}
      />
    </TableContainer>
  )
}
