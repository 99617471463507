import RoomCell from '@/components/Containers/AdminUsersTotalTable/RoomCell.tsx'
import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { TableImageTitle } from '@/components/Tables/columns/TableImageTitle/TableImageTitle'
import TableContainer from '@/components/Tables/components/TableContainer'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels'
import { makeColumnFixed } from '@/components/Tables/utils/makeColumnFixed'
import { makeFooterColumnFixed } from '@/components/Tables/utils/makeFooterColumnFixed'
import { WithAlert } from '@/components/Utils/WithAlert/WithAlert'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import { useSnackBar } from '@/context/SnackbarContext.tsx'
import { useTheme } from '@/context/ThemeContext.tsx'
import { useSetPlayerRoomBalancesById } from '@/services/hooks/cabinet/admin/useSetPlayerRoomsBalancesById.ts'
import { CurrencyHelper } from '@/utils/CurrencyHelper'
import {
  Box,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from '@mui/material'
import MUIDataTable from 'mui-datatables'

import { TableHeader } from '@/components/Containers/AdminUsersTotalTable/types'

import { useAdminUserTotalTable } from './useAdminUserTotalTable'

const LIMIT_DEFAULT = 500

export const AdminUsersTotalTable = () => {
  const { theme } = useTheme()
  const background: string = theme === 'light' ? 'white' : '#121212'
  const { table, isLoading, isError, errorMessage } = useAdminUserTotalTable()
  const { showSnackBar } = useSnackBar()
  const setPlayerRoomBalances = useSetPlayerRoomBalancesById({
    onSuccess: () => showSnackBar('Успешно сохранено', 'success'),
    onError: () => showSnackBar('Не удалось сохранить', 'error'),
  })

  return (
    <WithLoader isLoading={isLoading}>
      <WithAlert isRestricted={isError} message={errorMessage}>
        <Box>
          {table.body && (
            <TableContainer>
              <MUIDataTable
                title="Средства на румах"
                data={table.body}
                columns={table.columnsOrder.map((columnId, idx) => {
                  const column = table.header[columnId]

                  const columnWidth = (() => {
                    switch (column?.additional?.body?.size) {
                      case 'small':
                        return 50
                      case 'medium':
                        return 90
                      case 'large':
                        return 130
                      default:
                        return 50
                    }
                  })()

                  const isRoom = columnId.startsWith('rooms')

                  return {
                    name: column.name,
                    label: column.label,
                    options: {
                      ...(column.additional?.head?.fixed &&
                        makeColumnFixed({
                          columnPosition: idx,
                          columnWidth,
                          background,
                        })),
                      display:
                        typeof column.display === 'boolean'
                          ? !!column.display
                          : true,
                      customHeadLabelRender: () => {
                        return (
                          <TableImageTitle
                            disableTooltip
                            disableAvatar={!column?.additional?.head?.avatar}
                            title={column.label}
                            src={
                              typeof column?.additional?.head?.avatar ===
                              'string'
                                ? column?.additional?.head?.avatar
                                : ''
                            }
                            typographyProps={{
                              textAlign: 'left',
                            }}
                          />
                        )
                      },
                      customBodyRenderLite: dataIndex => {
                        const row = table.body?.[dataIndex]
                        const columnNames = column.name.split('.')
                        const value = columnNames.reduce(
                          // @ts-ignore
                          (value, key) => value?.[key] ?? '-',
                          row,
                        )

                        const formatted = (() => {
                          if (
                            column?.additional?.body?.currency &&
                            typeof value === 'number'
                          ) {
                            return CurrencyHelper.formatCurrency(
                              value,
                              column.additional.body?.currency,
                            )
                          }

                          return String(value)
                        })()
                        const props = {
                          disableTooltip: true,
                          disableAvatar: !column?.additional?.body?.avatar,
                          title: formatted || '0',
                          src:
                            typeof column?.additional?.body?.avatar === 'string'
                              ? column?.additional?.body?.avatar
                              : '',
                        }
                        return (
                          <TableColumn disableTooltip minWidth={columnWidth}>
                            {!isRoom && <TableImageTitle {...props} />}
                            {isRoom && (
                              <RoomCell
                                {...props}
                                handleSubmit={data => {
                                  void setPlayerRoomBalances.mutate({
                                    userId: row?.userId ?? 0,
                                    balances: [
                                      {
                                        room: {
                                          id:
                                            column?.additional?.body?.roomId ??
                                            0,
                                        },
                                        amount: +data.totalAmount || 0,
                                      },
                                    ],
                                  })
                                }}
                              />
                            )}
                          </TableColumn>
                        )
                      },
                    },
                  }
                })}
                options={{
                  count: table.body.length,
                  rowsPerPage: LIMIT_DEFAULT,
                  rowsPerPageOptions: [10, 20, 50, 100, 200, 500],
                  serverSide: false,
                  sort: true,
                  filter: false,
                  print: false,
                  search: false,
                  download: false,
                  viewColumns: true,
                  resizableColumns: false,
                  pagination: true,
                  responsive: 'standard',
                  tableBodyHeight: '700px',
                  fixedHeader: true,
                  fixedSelectColumn: true,
                  confirmFilters: false,
                  enableNestedDataAccess: '.',
                  selectableRows: 'none',
                  textLabels: createTextLabels({
                    isLoading: isLoading,
                    textLabels: {
                      body: {
                        noMatch: 'Пользователей не найдено',
                      },
                    },
                  }),
                  customTableBodyFooterRender: ({ columns }) => {
                    return (
                      <TableFooter
                        sx={{
                          position: 'sticky',
                          left: 0,
                          bottom: `-2px`,
                          width: '100%',
                          zIndex: 1000,
                          background,
                        }}
                      >
                        <TableRow>
                          {columns.map((column, idx) => {
                            const head =
                              table.header[column.name as keyof TableHeader]

                            if (!head || column.display !== 'true') {
                              return null
                            }

                            const sum =
                              typeof head?.additional?.footer?.sum === 'number'
                                ? head.additional.footer.sum
                                : ''
                            const sumFormatted =
                              typeof sum === 'number' &&
                              head?.additional?.body?.currency
                                ? CurrencyHelper.formatCurrency(
                                    sum,
                                    head?.additional?.body?.currency,
                                  )
                                : ''

                            const text = head?.additional?.footer?.text || ''

                            return (
                              <TableCell
                                variant="footer"
                                key={column.name}
                                {...(head.additional.head?.fixed
                                  ? makeFooterColumnFixed({
                                      columnPosition: idx,
                                      background,
                                    })
                                  : {
                                      sx: {
                                        borderBottom: 0,
                                      },
                                    })}
                              >
                                <Typography fontWeight={600} color="primary">
                                  {sumFormatted || text || '-'}
                                </Typography>
                              </TableCell>
                            )
                          })}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            {...makeFooterColumnFixed({
                              columnPosition: 0,
                              background,
                            })}
                          >
                            <Typography fontWeight={600} color="secondary">
                              Сумма отрицательных текущих профитов:{' '}
                              {CurrencyHelper.formatCurrency(
                                table.footer.negativeProfitsSum,
                                'USD',
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell
                            colSpan={columns.length - 6}
                            {...makeFooterColumnFixed({
                              columnPosition: 1,
                              background,
                            })}
                          />
                        </TableRow>
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            {...makeFooterColumnFixed({
                              columnPosition: 0,
                              background,
                            })}
                          >
                            <Typography fontWeight={600} color="secondary">
                              Всего на счетах игроков:{' '}
                              {CurrencyHelper.formatCurrency(
                                table.footer.moneyStoragesSum,
                                'USD',
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell
                            colSpan={columns.length - 6}
                            {...makeFooterColumnFixed({
                              columnPosition: 1,
                              background,
                            })}
                          />
                        </TableRow>
                      </TableFooter>
                    )
                  },
                }}
                components={{
                  TableFilterList: () => null,
                }}
              />
            </TableContainer>
          )}
        </Box>
      </WithAlert>
    </WithLoader>
  )
}
