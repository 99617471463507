import { useState } from 'react'

import {
  ConfirmModal,
  ConfirmModalProps,
} from '@/components/Modals/ConfirmModal/ConfirmModal'
import {
  TransferModal,
  TransferModalProps,
} from '@/components/Modals/TransferModal/TransferModal'
import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { TableCreatedAt } from '@/components/Tables/columns/TableCreatedAt/TableCreatedAt'
import { TableImageTitle } from '@/components/Tables/columns/TableImageTitle/TableImageTitle'
import { TableScreenshot } from '@/components/Tables/columns/TableScreenshot/TableScreenshot'
import { TableUser } from '@/components/Tables/columns/TableUser/TableUser'
import TableContainer from '@/components/Tables/components/TableContainer'
import { createTextFieldFilter } from '@/components/Tables/createTextFieldFilter'
import { createMultipleSelectFilter } from '@/components/Tables/filters/createMultipleSelectFilter'
import { createRangeFilter } from '@/components/Tables/filters/createRangeFilter'
import { useGetPlayersSelectValues } from '@/components/Tables/hooks/useGetPlayersSelectValues.ts'
import { useGetRoomsSelectValues } from '@/components/Tables/hooks/useGetRoomsSelectValues.ts'
import { useMenu } from '@/components/Tables/hooks/useMenu'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels'
import { WithLoader } from '@/components/Utils/WithLoader/WithLoader'
import {
  ROOMS_LIMITATIONS,
  transferPurposeMap,
  transferPurposePersonalSelectOptions,
  transferPurposeProjectSelectOptions,
} from '@/constants'
import { useSnackBar } from '@/context/SnackbarContext'
import { FundsTransferRo } from '@/services/data-contracts'
import { useGetPlayerFundsByToken } from '@/services/hooks/cabinet/player/useGetPlayerFundsByToken'
import { useDeleteFundsTransfer } from '@/services/hooks/funds-transfers/useDeleteFundsTransfer'
import { useGetPersonalFundsTransfersByToken } from '@/services/hooks/funds-transfers/useGetPersonalFundsTransfersByToken'
import { useGetProjectFundsTransfersByToken } from '@/services/hooks/funds-transfers/useGetProjectFundsTransfersByToken'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { CurrencyHelper } from '@/utils/CurrencyHelper'
import { RoleHelper } from '@/utils/RoleHelper'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables'
import { ShowFnOutput, useModal } from 'mui-modal-provider'

const SORT_DEFAULT = 'createdAt:DESC'
const FILTERS_DEFAULT: Record<string, unknown | undefined> = {
  'createdAt.from': undefined,
  'createdAt.to': undefined,
}

export interface FundsTransfersTableProps {
  mode: 'personal' | 'project'
}

export const FundsTransfersTable = ({ mode }: FundsTransfersTableProps) => {
  const tableDefault = useTableDefaultOptions()
  const tablePagination = useTablePagination()
  const belowMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const [filters, setFilters] = useState(FILTERS_DEFAULT)
  const { showModal } = useModal()
  const { showSnackBar } = useSnackBar()

  const { currentUser } = useCurrentUser()
  const isAdmin = RoleHelper.isAdmin(currentUser?.role)

  const { playerMultipleSelectOptions } = useGetPlayersSelectValues()
  const { roomsSelectValues } = useGetRoomsSelectValues({
    category: {
      project: ROOMS_LIMITATIONS.PROJECT_TRANSFERS,
      personal: ROOMS_LIMITATIONS.PERSONAL_TRANSFERS,
    }[mode],
  })

  const { data: playerFunds, isInitialLoading: isLoadingPlayerFunds } =
    useGetPlayerFundsByToken({
      enabled: RoleHelper.isPlayer(currentUser?.role),
    })

  const query = {
    get: {
      personal: useGetPersonalFundsTransfersByToken,
      project: useGetProjectFundsTransfersByToken,
    },
  }

  const title = {
    project: 'История переводов в проекте',
    personal: 'История личных переводов',
  }

  const sumTitle = {
    project: 'Всего получено от проекта',
    personal: 'Всего личных средств',
  }

  const createButtonLabel = {
    project: 'Создать перевод',
    personal: 'Создать личный перевод',
  }

  const useGetQuery = query.get[mode]

  const { data: response, isLoading } = useGetQuery({
    ...tablePagination.queryParams,
    ...filters,
    sort: SORT_DEFAULT,
  })

  const [data, count] = response ? [response.data, response.count] : [[], 0]

  const handleFilterConfirm: MUIDataTableOptions['onFilterConfirm'] =
    filterList => {
      const [
        createdAt,
        transferPurpose,
        ,
        // sender.id (no filter, skip)
        sender,
        room,
        recipient,
        factAmount,
        transferFee,
        ,
        // screenshot.location (no filter, skip)
        comment,
        createdBy,
        ,
        // updatedBy (no filter, skip)
        fundsRequestId,
        // __actions (no filter, skip)
        ,
      ] = filterList

      tablePagination.helpers.resetPage()
      setFilters({
        'createdAt.from': createdAt?.[0]
          ? new Date(createdAt[0]).toISOString()
          : undefined,
        'createdAt.to': createdAt?.[1]
          ? new Date(createdAt[1]).toISOString()
          : undefined,
        'transferPurpose.in': transferPurpose || undefined,
        'sender.in': sender || undefined,
        'room.in': room || undefined,
        'recipient.in': recipient || undefined,
        'factAmount.from': factAmount?.[0] || undefined,
        'factAmount.to': factAmount?.[1] || undefined,
        'transferFee.from': transferFee?.[0] || undefined,
        'transferFee.to': transferFee?.[1] || undefined,
        'comment.like': comment?.[0] || undefined,
        'createdBy.in': createdBy || undefined,
        'fundsRequest.in': fundsRequestId,
      })
    }

  const handleOpenCreateModal = () => {
    const text = {
      project: {
        success: 'Перевод внутри проекта успешно создан',
        error: 'Ошибка при создании перевода внутри проекта',
      },
      personal: {
        success: 'Личный перевод успешно создан',
        error: 'Ошибка при создании личного перевода',
      },
    }

    const modal = showModal<TransferModalProps>(TransferModal, {
      mode: 'create',
      type: mode,
      fundsTransferId: undefined,
      onSuccess: () => {
        modal.hide()
        showSnackBar(text[mode].success, 'success')
      },
      onError: err => {
        showSnackBar(text[mode].error, 'error', err)
      },
    })
  }

  const handleOpenEditModal = (row: FundsTransferRo) => {
    const text = {
      project: {
        success: 'Перевод внутри проекта успешно отредактирован',
        error: 'Ошибка при редактировании перевода внутри проекта',
      },
      personal: {
        success: 'Личный перевод успешно отредактирован',
        error: 'Ошибка при редактировании личного перевода',
      },
    }

    const modal = showModal<TransferModalProps>(TransferModal, {
      mode: 'edit',
      type: mode,
      fundsTransferId: row.id,
      onSuccess: () => {
        modal.hide()
        showSnackBar(text[mode].success, 'success')
      },
      onError: err => {
        showSnackBar(text[mode].error, 'error', err)
      },
    })
  }

  const fundsTransferDelete = useDeleteFundsTransfer({
    onSuccess: () => showSnackBar('Перевод успешно удален', 'success'),
    onError: () => showSnackBar('Ошибка при удалении перевода', 'error'),
  })

  const handleDeleteFundsTransfer = (row: FundsTransferRo) => {
    const modal: ShowFnOutput<ConfirmModalProps> = showModal(ConfirmModal, {
      headerText: `Вы действительно хотите удалить перевод?`,
      buttons: {
        confirm: {
          onClick: () => {
            fundsTransferDelete.mutate({ id: row.id })
            modal.hide()
          },
          children: 'Удалить',
        },
        cancel: {
          onClick: () => modal.hide(),
        },
      },
    })
  }

  const { TableMenuColumn } = useMenu<FundsTransferRo>({
    data,
    options: [
      ...(isAdmin
        ? [
            {
              id: 'edit',
              label: 'Редактировать',
              onClick: handleOpenEditModal,
            },
            {
              id: 'delete',
              label: 'Удалить',
              onClick: handleDeleteFundsTransfer,
            },
          ]
        : []),
    ],
  })

  const totalUsd: number | null = playerFunds
    ? {
        project: playerFunds?.project.totalUSD,
        personal: playerFunds?.personalContract.totalUSD,
      }[mode] || 0
    : null

  return (
    <TableContainer>
      <MUIDataTable
        title={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ flex: 1.5 }}>
              <Typography variant="h6">{sumTitle[mode]}</Typography>
              <Typography variant="body2" fontStyle="italic">
                {title[mode]}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}
            >
              <WithLoader isLoading={isLoadingPlayerFunds} size={14}>
                {typeof totalUsd === 'number' && (
                  <Typography variant="h4" fontStyle="normal" component="span">
                    {CurrencyHelper.formatCurrency(totalUsd, 'USD')}
                  </Typography>
                )}
              </WithLoader>
            </Box>
          </Box>
        }
        data={data}
        columns={[
          {
            name: 'createdAt',
            label: 'Дата',
            options: {
              filter: true,
              filterType: 'custom',
              filterOptions: createRangeFilter({ type: 'datetime-local' }),
              customBodyRenderLite: idx => {
                const row = data?.[idx]

                return row && TableCreatedAt(row.createdAt)
              },
            },
          },
          {
            name: 'transferPurpose',
            label: 'Тип перевода',
            options: {
              customBodyRenderLite: idx => {
                const row = data?.[idx]

                return (
                  row && (
                    <TableColumn minWidth={60}>
                      <Typography variant="body2">
                        {transferPurposeMap[row.transferPurpose] ||
                          row.transferPurpose}
                      </Typography>
                    </TableColumn>
                  )
                )
              },
              filter: true,
              filterType: 'custom',
              filterOptions: {
                ...createMultipleSelectFilter(
                  mode === 'project'
                    ? transferPurposeProjectSelectOptions
                    : transferPurposePersonalSelectOptions,
                  { enableAvatar: false },
                ),
                fullWidth: false,
              },
            },
          },
          {
            name: 'sender.id',
            label: 'Отправитель',
            options: {
              display: mode === 'personal',
              filter: false,
              customBodyRenderLite: idx => {
                const row = data?.[idx]

                return (
                  row &&
                  TableUser(row.sender?.username || row.recipient?.username)
                )
              },
            },
          },
          {
            name: 'sender.username',
            label: 'Отправитель',
            options: {
              display: mode === 'project',
              filter: isAdmin,
              filterType: 'custom',
              filterOptions: {
                ...createMultipleSelectFilter(playerMultipleSelectOptions, {
                  enableAvatar: false,
                }),
                fullWidth: false,
              },
              customBodyRenderLite: idx => {
                const row = data?.[idx]
                return row && TableUser(row.sender?.username)
              },
            },
          },
          {
            name: 'room.name',
            label: 'Счёт',
            options: {
              filter: true,
              filterType: 'custom',
              filterOptions: {
                ...createMultipleSelectFilter(roomsSelectValues, {
                  enableAvatar: true,
                }),
                fullWidth: false,
              },
              customBodyRenderLite: idx => {
                const row = data?.[idx]

                return (
                  !!row && (
                    <TableColumn minWidth={70}>
                      <TableImageTitle
                        title={row.room.name}
                        src={row.room?.avatar?.location}
                      />
                    </TableColumn>
                  )
                )
              },
            },
          },
          {
            name: 'recipient.username',
            label: 'Получатель',
            options: {
              display: mode === 'project',
              filter: isAdmin,
              filterType: 'custom',
              filterOptions: {
                ...createMultipleSelectFilter(playerMultipleSelectOptions, {
                  enableAvatar: false,
                }),
                fullWidth: false,
              },
              customBodyRenderLite: idx => {
                const row = data?.[idx]
                return row && TableUser(row.recipient?.username)
              },
            },
          },
          {
            name: 'factAmount',
            label: 'Сумма (факт)',
            options: {
              filter: true,
              filterType: 'custom',
              filterOptions: createRangeFilter({ type: 'number' }),
              customBodyRenderLite: idx => {
                const row = data[idx]

                const amount = (() => {
                  const hasSender = Boolean(row?.sender?.id)
                  const factAmount = row?.factAmount || 0

                  if (mode === 'personal' && hasSender) {
                    return -factAmount
                  }

                  return factAmount
                })()

                return CurrencyHelper.formatCurrency(amount, row?.room.currency)
              },
            },
          },
          {
            name: 'transferFee',
            label: 'Комиссия',
            options: {
              display: mode === 'project',
              filter: mode === 'project',
              filterType: 'custom',
              filterOptions: createRangeFilter({ type: 'number' }),
              customBodyRenderLite: idx => {
                const row = data[idx]
                return CurrencyHelper.formatCurrency(
                  row?.transferFee || 0,
                  row?.room.currency,
                )
              },
            },
          },
          {
            name: 'screenshot.location',
            label: 'Скриншот',
            options: {
              filter: false,
              customBodyRenderLite: idx =>
                TableScreenshot(data?.[idx]?.screenshot?.location),
            },
          },
          {
            name: 'comment',
            label: 'Комментарий',
            options: {
              filter: true,
              filterType: 'textField',
              customBodyRenderLite: idx => {
                const row = data?.[idx]

                return (
                  row && (
                    <TableColumn
                      minWidth={150}
                      maxWidth={300}
                      sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
                    >
                      {row.comment}
                    </TableColumn>
                  )
                )
              },
            },
          },
          {
            name: 'createdBy.username',
            label: 'Создал перевод',
            options: {
              filter: mode === 'project' || isAdmin,
              filterType: 'custom',
              filterOptions: {
                ...createMultipleSelectFilter(playerMultipleSelectOptions, {
                  enableAvatar: false,
                }),
                fullWidth: false,
              },
              display: mode === 'project' || isAdmin,
              customBodyRenderLite: idx => {
                const row = data?.[idx]

                return row && TableUser(row.createdBy?.username)
              },
            },
          },
          {
            name: 'updatedBy.username',
            label: 'Отредактировал перевод',
            options: {
              display: isAdmin,
              customBodyRenderLite: idx => {
                const row = data?.[idx]

                return row && TableUser(row.updatedBy?.username)
              },
              filter: false,
            },
          },
          {
            name: 'fundsRequest.id',
            label: 'Запрос на перевод (ID)',
            options: {
              display: isAdmin && mode === 'project',
              filter: true,
              filterType: 'custom',
              filterOptions: {
                ...createTextFieldFilter({ type: 'number' }),
              },
              customBodyRenderLite: idx => {
                const row = data?.[idx]

                return (
                  row && (
                    <TableColumn minWidth={60}>
                      {row?.fundsRequest?.id}
                    </TableColumn>
                  )
                )
              },
            },
          },
          {
            name: '__actions__',
            label: ' ',
            options: {
              filter: false,
              sort: false,
              customBodyRenderLite: TableMenuColumn,
            },
          },
        ]}
        options={{
          ...tableDefault.options,
          ...tablePagination.options,
          count,
          tableBodyHeight: '500px',
          filter: true,
          onFilterConfirm: handleFilterConfirm,
          textLabels: createTextLabels({
            isLoading,
            textLabels: {
              body: {
                noMatch: 'Переводов не найдено',
              },
            },
          }),
          customToolbar: () => {
            if (belowMd) {
              return (
                <IconButton onClick={handleOpenCreateModal}>
                  <AddIcon />
                </IconButton>
              )
            }
            return (
              <Box sx={{ display: 'inline-flex' }} ml={2}>
                <Button
                  startIcon={<AddIcon />}
                  color={mode === 'project' ? 'primary' : 'secondary'}
                  variant="contained"
                  size="small"
                  onClick={handleOpenCreateModal}
                >
                  {createButtonLabel[mode]}
                </Button>
              </Box>
            )
          },
        }}
        components={tableDefault.components}
      />
    </TableContainer>
  )
}
