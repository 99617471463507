import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { Box } from '@mui/material'

import DashboardMenu from './DashboardMenu'
import { ROUTES_MAP } from './DashboardNavigation'

const DashboardDrawer = () => {
  const { currentUser } = useCurrentUser()

  const routes = currentUser?.role ? ROUTES_MAP[currentUser.role] : []

  return (
    <Box>
      <Box
        sx={{
          pt: 2,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          a: {
            justifyContent: 'flex-start',
            px: 3,
            py: 1,
          },
          button: {
            justifyContent: 'flex-start',
            px: 3,
            py: 1,
          },
        }}
      >
        <DashboardMenu routes={routes} />
      </Box>
    </Box>
  )
}

export default DashboardDrawer
