import { useEffect, useState } from 'react'

import { ApproveGameRequestModal } from '@/components/Modals/ApproveGameRequestModal/ApproveGameRequestModal'
import {
  ConfirmModal,
  ConfirmModalProps,
} from '@/components/Modals/ConfirmModal/ConfirmModal.tsx'
import { CreateGameRequestModal } from '@/components/Modals/CreateGameRequestModal/CreateGameRequestModal'
import { DeclinedPlayGameRequestModal } from '@/components/Modals/DeclinedPlayGameRequestModal/DeclinedPlayGameRequestModal'
import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { TableCreatedAt } from '@/components/Tables/columns/TableCreatedAt/TableCreatedAt'
import { TableDate } from '@/components/Tables/columns/TableDate/TableDate'
import { TableImageTitle } from '@/components/Tables/columns/TableImageTitle/TableImageTitle'
import { TableScreenshot } from '@/components/Tables/columns/TableScreenshot/TableScreenshot'
import { TableUser } from '@/components/Tables/columns/TableUser/TableUser'
import TableContainer from '@/components/Tables/components/TableContainer'
import { createMultipleSelectFilter } from '@/components/Tables/filters/createMultipleSelectFilter'
import { createRangeFilter } from '@/components/Tables/filters/createRangeFilter.tsx'
import { useGetPlayersSelectValues } from '@/components/Tables/hooks/useGetPlayersSelectValues.ts'
import { useGetRoomsSelectValues } from '@/components/Tables/hooks/useGetRoomsSelectValues.ts'
import { useMenu } from '@/components/Tables/hooks/useMenu.tsx'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'
import { useTableSort } from '@/components/Tables/hooks/useTableSort.ts'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels'
import {
  gameRequestsStatusMap,
  gameRequestsStatusMultipleSelectOptions,
  ROOMS_LIMITATIONS,
} from '@/constants'
import { useSnackBar } from '@/context/SnackbarContext'
import {
  GameRequestsControllerFindAllParams,
  GameRequestSingleRo,
} from '@/services/data-contracts'
import { useDeleteGameRequest } from '@/services/hooks/game-requests/useDeleteGameRequest.ts'
import { useGetGameRequests } from '@/services/hooks/game-requests/useGetGameRequests'
import { useGetFilterByName } from '@/services/hooks/tournament-filters/useGetFilterByName.ts'
import { useSetFilter } from '@/services/hooks/tournament-filters/useSetFilter.ts'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { RoleHelper } from '@/utils/RoleHelper.ts'
import AddIcon from '@mui/icons-material/Add'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import {
  Alert,
  AlertProps,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { formatDistance, parseISO } from 'date-fns'
import { ru as dateFnsRuLocale } from 'date-fns/locale'
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables'
import { ShowFnOutput, useModal } from 'mui-modal-provider'

const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

const FILTERS_DEFAULT: GameRequestsControllerFindAllParams = {
  'status.in': undefined,
  'date.from': undefined,
  'date.to': undefined,
  'buyIn.from': undefined,
  'buyIn.to': undefined,
  'createdBy.in': undefined,
  'room.in': undefined,
  'timezone.equal': USER_TIMEZONE,
}

export const GameRequestsTable = () => {
  const tableDefault = useTableDefaultOptions()
  const tablePagination = useTablePagination()
  const tableSort = useTableSort(['createdAt', 'DESC'])
  const belowMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [filters, setFilters] = useState(FILTERS_DEFAULT)
  const { showModal } = useModal()
  const { showSnackBar } = useSnackBar()
  const [canSave, setCanSave] = useState(false)
  const saveFilter = useSetFilter()
  const { data: savedFilter } = useGetFilterByName({ name: 'request' })
  const { currentUser } = useCurrentUser()
  const { playerMultipleSelectOptions } = useGetPlayersSelectValues()
  const { roomsSelectValues } = useGetRoomsSelectValues({
    category: ROOMS_LIMITATIONS.TOURNAMENTS,
  })
  const { data: gameRequests, isFetching: isGameRequestsFetching } =
    useGetGameRequests({
      ...tablePagination.queryParams,
      ...tableSort.queryParams,
      ...filters,
    })

  const [data, count] = gameRequests
    ? [gameRequests.data, gameRequests.count]
    : [[], 0]

  const deleteGameRequest = useDeleteGameRequest({
    onSuccess: () => showSnackBar('Запрос на игру успешно удален', 'success'),
    onError: err =>
      showSnackBar('Ошибка при удалении запроса на игру', 'error', err),
  })

  const handleFilterConfirm: MUIDataTableOptions['onFilterConfirm'] =
    filterList => {
      // eslint-disable-next-line prefer-destructuring
      const status = filterList[8]
      const [, createdBy, room, buyIn, , date] = filterList
      tablePagination.helpers.resetPage()

      setFilters({
        'status.in': status,
        'createdBy.in': createdBy[0]
          ? (createdBy || []).map(Number)
          : undefined,
        'room.in': room ? (room || []).map(Number) : undefined,
        'date.from': date?.[0] ? date?.[0] : undefined,
        'date.to': date?.[1] ? date[1] : undefined,
        'buyIn.from': buyIn?.[0] ? +buyIn[0] : undefined,
        'buyIn.to': buyIn?.[1] ? +buyIn[1] : undefined,
        'timezone.equal': USER_TIMEZONE,
      })
    }

  const handleOpenCreateGameRequest = () => {
    const modal = showModal(CreateGameRequestModal, {
      onSuccess: () => {
        showSnackBar('Запрос на игру успешно создан', 'success')
        modal.hide()
      },
      onError: err => {
        showSnackBar('Ошибка при создании запроса на игру', 'error', err)
      },
    })
  }

  const handleApproveGameRequest = (id: number) => {
    const modal = showModal(ApproveGameRequestModal, {
      gameRequestId: id,
      mode: 'approved',
      onSuccess: () => {
        showSnackBar(`Запрос на игру с ID ${id} успешно утвержден.`, 'success')
        modal.hide()
      },
      onError: err => {
        showSnackBar(
          `Ошибка при утверждении запроса на игру с ID ${id}`,
          'error',
          err,
        )
      },
    })
  }

  const handleDeclineGameRequest = (id: number) => {
    const modal = showModal(ApproveGameRequestModal, {
      gameRequestId: id,
      mode: 'declined',
      onSuccess: () => {
        showSnackBar(`Запрос на игру с ID ${id} успешно отклонен.`, 'success')
        modal.hide()
      },
      onError: err => {
        showSnackBar(
          `Ошибка при отклонении запроса на игру с ID ${id}`,
          'error',
          err,
        )
      },
    })
  }

  const handleDeclinedPlayedGameRequest = (row: GameRequestSingleRo) => {
    const modal = showModal(DeclinedPlayGameRequestModal, {
      gameRequest: row,
      mode: 'declined_played',
      onSuccess: () => {
        showSnackBar(
          `Запрос на игру с ID ${row.id} успешно отмечен. Сыгран. Автоматический личный перевод создан.`,
          'success',
        )
        modal.hide()
      },
      onError: err => {
        showSnackBar(
          `Ошибка при отметке запроса на игру с ID ${row.id}`,
          'error',
          err,
        )
      },
    })
  }

  const handleDeclinedNotPlayedGameRequest = (row: GameRequestSingleRo) => {
    const modal = showModal(DeclinedPlayGameRequestModal, {
      gameRequest: row,
      mode: 'declined_not_played',
      onSuccess: () => {
        showSnackBar(
          `Запрос на игру с ID ${row.id} успешно отмечен. Не сыгран`,
          'success',
        )
        modal.hide()
      },
      onError: err => {
        showSnackBar(
          `Ошибка при отметке запроса на игру с ID ${row.id}`,
          'error',
          err,
        )
      },
    })
  }

  const handleDeleteGameRequest = (row: GameRequestSingleRo) => {
    const modal: ShowFnOutput<ConfirmModalProps> = showModal(ConfirmModal, {
      headerText: `Вы действительно хотите удалить запрос на игру? ID: ${row.id}`,
      buttons: {
        confirm: {
          onClick: () => {
            deleteGameRequest.mutate({ id: row.id })
            modal.hide()
          },
          children: 'Удалить',
        },
        cancel: {
          onClick: () => modal.hide(),
        },
      },
    })
  }

  const canCreateNewGameRequest =
    RoleHelper.isPlayer(currentUser?.role) ||
    RoleHelper.isAdmin(currentUser?.role)

  const canModerateGameRequests =
    RoleHelper.isSelector(currentUser?.role) ||
    RoleHelper.isAdmin(currentUser?.role)

  const canDeleteAllGameRequests = RoleHelper.isAdmin(currentUser?.role)

  const { TableMenuColumn } = useMenu<GameRequestSingleRo>({
    data,
    options: [
      function (row) {
        const hasPendingStatus = row.status === 'pending'
        const isOwner = row.createdBy.id === currentUser?.id

        const canDeleteOwnRequest = hasPendingStatus && isOwner

        if (!canDeleteOwnRequest && !canDeleteAllGameRequests) {
          return null
        }

        return {
          id: 'delete',
          label: 'Удалить',
          onClick: handleDeleteGameRequest,
        }
      },
    ],
  })

  useEffect(() => {
    if (canSave) {
      saveFilter.mutate({ name: 'request', value: filters })
      setCanSave(false)
    }
  }, [canSave, filters, saveFilter])

  useEffect(() => {
    if (savedFilter) {
      setFilters(savedFilter?.value as typeof filters)
    }
  }, [savedFilter])

  return (
    <TableContainer>
      <MUIDataTable
        title={
          <>
            <Typography variant="h6">Запросы на турнир</Typography>
            <Typography variant="caption">
              Ваш часовой пояс: {USER_TIMEZONE}
            </Typography>
          </>
        }
        columns={[
          {
            name: 'createdAt',
            label: 'Создано',
            options: {
              sort: true,
              filter: false,
              customBodyRender: TableCreatedAt,
            },
          },
          {
            name: 'createdBy.username',
            label: 'Создал',
            options: {
              sort: true,
              filter: RoleHelper.isAdmin(currentUser?.role),
              customBodyRender: TableUser,
              filterType: 'custom',
              filterOptions: {
                ...createMultipleSelectFilter(playerMultipleSelectOptions, {
                  enableAvatar: false,
                }),
                fullWidth: true,
              },
              // @ts-ignore
              filterList: (filters['createdBy.in'] ?? []).map(item => item),
            },
          },
          {
            name: 'room.name',
            label: 'Рум',
            options: {
              sort: true,
              filter: true,
              filterType: 'custom',
              filterOptions: {
                ...createMultipleSelectFilter(roomsSelectValues, {
                  enableAvatar: true,
                }),
                fullWidth: true,
              },
              // @ts-ignore
              filterList: (filters['room.in'] ?? []).map(item => item),
              customBodyRender: (value, meta) => {
                const row = data[meta.rowIndex]

                return (
                  <TableColumn minWidth={70} maxWidth={100}>
                    <TableImageTitle
                      title={value}
                      src={row.room?.avatar?.location}
                    />
                  </TableColumn>
                )
              },
            },
          },
          {
            name: 'buyIn',
            label: 'BI',
            options: {
              sort: true,
              filter: true,
              filterType: 'custom',
              filterOptions: createRangeFilter({
                type: 'number',
                inputProps: {
                  from: {
                    placeholder: 'От',
                  },
                  to: {
                    placeholder: 'До',
                  },
                },
              }),
              filterList: [
                filters?.['buyIn.from']?.toString() || '',
                filters?.['buyIn.to']?.toString() || '',
              ],
              customBodyRender: value => (
                <TableColumn minWidth={30} maxWidth={30}>
                  {value}
                </TableColumn>
              ),
            },
          },
          {
            name: 'title',
            label: 'Название турнира',
            options: {
              sort: true,
              filter: false,
              customBodyRender: (value: string) => (
                <TableColumn minWidth={180} maxWidth={230}>
                  <Typography variant="body2">{value}</Typography>
                </TableColumn>
              ),
            },
          },
          {
            name: 'date',
            label: 'Дата проведения',
            options: {
              sort: true,
              filter: true,
              filterType: 'custom',
              filterOptions: createRangeFilter({ type: 'date' }),
              customBodyRender: TableDate,
              filterList: [
                filters['date.from'] ? filters['date.from'] : '',
                filters['date.to'] ? filters['date.to'] : '',
              ],
            },
          },
          {
            name: 'startTime',
            label: 'Начало',
            options: {
              sort: true,
              filter: false,
              customBodyRender: (value: string) => {
                return (
                  <TableColumn minWidth={80} maxWidth={80}>
                    {(value || '').slice(0, 5)}
                  </TableColumn>
                )
              },
            },
          },
          {
            name: 'screenshot.location',
            label: 'Скриншот',
            options: {
              filter: false,
              sort: false,
              customBodyRender: TableScreenshot,
            },
          },
          {
            name: 'status',
            label: 'Статус',
            options: {
              sort: true,
              customBodyRender: (
                value:
                  | 'pending'
                  | 'approved'
                  | 'declined'
                  | 'declined_played'
                  | 'declined_not_played',
              ) => {
                const colorMap: Record<
                  | 'pending'
                  | 'approved'
                  | 'declined'
                  | 'declined_played'
                  | 'declined_not_played',
                  AlertProps['color']
                > = {
                  pending: 'info',
                  approved: 'success',
                  declined: 'error',
                  declined_played: 'warning',
                  declined_not_played: 'error',
                }

                return (
                  <TableColumn minWidth={180}>
                    <Alert color={colorMap[value] || 'info'}>
                      {gameRequestsStatusMap[value] || value}
                    </Alert>
                  </TableColumn>
                )
              },
              filter: true,
              filterType: 'custom',
              filterOptions: {
                ...createMultipleSelectFilter(
                  gameRequestsStatusMultipleSelectOptions,
                  { enableAvatar: false },
                ),
                fullWidth: true,
              },
              // @ts-ignore
              filterList: (filters['status.in'] ?? []).map(item => item),
            },
          },
          {
            name: 'studentComment',
            label: 'Комментарий игрока',
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value: string) => (
                <TableColumn minWidth={120} maxWidth={120}>
                  <span>{value}</span>
                </TableColumn>
              ),
            },
          },
          {
            name: 'moderatorComment',
            label: 'Комментарий селектора',
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value: string) => (
                <TableColumn minWidth={120} maxWidth={120}>
                  <span>{value}</span>
                </TableColumn>
              ),
            },
          },
          {
            name: '__game_request_markers__',
            label: ' ',
            options: {
              filter: false,
              sort: false,
              display: canModerateGameRequests,
              customBodyRenderLite: rowIndex => {
                const row: GameRequestSingleRo = data[rowIndex]
                // Moscow time
                const tournamentDate = parseISO(
                  `${row.date}T${row.startTime}+03:00`,
                )
                const createdAtDate = parseISO(row.createdAt)

                const isManuallyCreated = !row.scheduledTournament
                const isLateGameRequest =
                  createdAtDate.getTime() > tournamentDate.getTime()

                const gameRequestLateDistance = formatDistance(
                  tournamentDate,
                  createdAtDate,
                  {
                    locale: dateFnsRuLocale,
                    addSuffix: true,
                  },
                )

                return (
                  <ButtonGroup>
                    {isManuallyCreated && (
                      <Tooltip title="Данный запрос был создан вручную">
                        <IconButton>
                          <PriorityHighIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {isLateGameRequest && (
                      <Tooltip
                        title={
                          <>
                            <span>
                              Запрос на игру создан после начала турнира.
                            </span>
                            <br />
                            <span>
                              Турнир начался <b>{gameRequestLateDistance}</b> до
                              создания запроса.
                            </span>
                          </>
                        }
                      >
                        <IconButton>
                          <WatchLaterIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </ButtonGroup>
                )
              },
            },
          },
          {
            name: '__action__',
            label: ' ',
            options: {
              filter: false,
              sort: false,
              customBodyRenderLite: rowIndex => {
                const row = data[rowIndex]
                const isOwner = row.createdBy.id === currentUser?.id

                if (
                  row.status === 'approved' ||
                  row.status === 'declined_not_played'
                ) {
                  return null
                }

                return (
                  <ButtonGroup>
                    {row.status === 'pending' && canModerateGameRequests && (
                      <>
                        <Tooltip title="Разрешить. В пакет">
                          <IconButton
                            color="secondary"
                            onClick={() => handleApproveGameRequest(row.id)}
                          >
                            <ThumbUpIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Отказать. Игра от себя"
                          onClick={() => handleDeclineGameRequest(row.id)}
                        >
                          <IconButton color="error">
                            <ThumbDownIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    {row.status === 'declined' && isOwner && (
                      <>
                        <Tooltip title="Турнир сыгран. Указать результаты">
                          <IconButton
                            color="secondary"
                            onClick={() => handleDeclinedPlayedGameRequest(row)}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Турнир не сыгран"
                          onClick={() =>
                            handleDeclinedNotPlayedGameRequest(row)
                          }
                        >
                          <IconButton color="error">
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </ButtonGroup>
                )
              },
            },
          },
          {
            name: '__additional_action__',
            label: ' ',
            options: {
              filter: false,
              sort: false,
              customBodyRenderLite: TableMenuColumn,
            },
          },
        ]}
        data={data}
        options={{
          ...tableDefault.options,
          ...tablePagination.options,
          ...tableSort.options,
          count,
          tableBodyHeight: '500px',
          filter: true,
          onFilterConfirm: handleFilterConfirm,
          customFilterDialogFooter: (filterList, applyNewFilters) => {
            return (
              <Box display="flex" gap="16px" alignItems="center">
                {tableDefault.options?.customFilterDialogFooter?.(
                  filterList,
                  applyNewFilters,
                )}
                <Box marginTop="24px">
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleFilterConfirm(filterList)
                      applyNewFilters?.(filterList)
                      setCanSave(true)
                    }}
                  >
                    Сохранить и применить
                  </Button>
                </Box>
              </Box>
            )
          },
          textLabels: createTextLabels({
            isLoading: isGameRequestsFetching,
            textLabels: {
              body: {
                noMatch: 'Запросов на игру сейчас нет',
              },
            },
          }),
          customToolbar: () => {
            if (!canCreateNewGameRequest) {
              return null
            }
            if (belowMd) {
              return (
                <IconButton onClick={handleOpenCreateGameRequest}>
                  <AddIcon />
                </IconButton>
              )
            }
            return (
              <Box sx={{ display: 'inline-flex' }} ml={{ xs: 0, md: 2 }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleOpenCreateGameRequest}
                >
                  Новый запрос на турнир
                </Button>
              </Box>
            )
          },
        }}
        components={tableDefault.components}
      />
    </TableContainer>
  )
}
