import { Box, Button } from '@mui/material'
import { MUIDataTableOptions } from 'mui-datatables'

export const createFilterDialogFooter: MUIDataTableOptions['customFilterDialogFooter'] =
  (filterList, applyNewFilters) => {
    return (
      <Box mt={3}>
        <Button
          variant="contained"
          onClick={() => applyNewFilters?.(filterList)}
        >
          Применить фильтры
        </Button>
      </Box>
    )
  }
