import { useMemo, useState } from 'react'

import { useGetSort } from '@/components/Containers/RoomsTable/useGetSort.ts'
import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { TableCreatedAt } from '@/components/Tables/columns/TableCreatedAt/TableCreatedAt'
import { TableImageTitle } from '@/components/Tables/columns/TableImageTitle/TableImageTitle'
import TableContainer from '@/components/Tables/components/TableContainer'
import {
  createMultipleSelectFilter,
  MultipleSelectOption,
} from '@/components/Tables/filters/createMultipleSelectFilter'
import { createRangeFilter } from '@/components/Tables/filters/createRangeFilter'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'
import { useTableSort } from '@/components/Tables/hooks/useTableSort.ts'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels'
import { ROOMS_LIMITATIONS } from '@/constants'
import {
  ScheduledTournamentsControllerFindAllParams,
  ScheduledTournamentSingleRo,
} from '@/services/data-contracts'
import { useGetRooms } from '@/services/hooks/rooms/useGetRooms'
import { useGetScheduledTournaments } from '@/services/hooks/scheduled-tournaments/useGetScheduledTournaments'
import { isoStringToDatetimeLocal } from '@/utils/isoStringToDatetimeLocal'
import { roomsToMultipleSelectOptions } from '@/utils/mappers/roomsToMultipleSelectOptions'
import AddTaskIcon from '@mui/icons-material/AddTask'
import { ButtonGroup, IconButton, Typography } from '@mui/material'
import { addHours } from 'date-fns'
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables'

interface ScheduledTournamentsTableProps {
  onRowClick: (id: number) => void
}

const FRESH_TOURNAMENT_AGO_IN_HOURS = 12
const ONLY_FRESH_TOURNAMENTS_START_TIMESTAMP_FROM_DEFAULT = addHours(
  new Date(),
  -FRESH_TOURNAMENT_AGO_IN_HOURS,
)

const FILTERS_DEFAULT: ScheduledTournamentsControllerFindAllParams = {
  'startTimestamp.from':
    ONLY_FRESH_TOURNAMENTS_START_TIMESTAMP_FROM_DEFAULT.toISOString(),
  'startTimestamp.equal': undefined,
  'buyIn.equal': undefined,
  'name.like': undefined,
  'room.in': undefined,
}

const lang = {
  title: 'Турнир',
  header: 'Турниры',
  notFound: 'Не найдено ни одного турнира по заданным фильтрам',
}

export const ScheduledTournamentsTable = ({
  onRowClick,
}: ScheduledTournamentsTableProps) => {
  const { defaultSortString } = useGetSort()

  const tableDefault = useTableDefaultOptions()
  const tablePagination = useTablePagination()
  const tableSort = useTableSort(['startTimestamp', 'ASC'])

  const [filters, setFilters] =
    useState<ScheduledTournamentsControllerFindAllParams>(FILTERS_DEFAULT)

  const { data: tournaments, isFetching: isTournamentsFetching } =
    useGetScheduledTournaments({
      ...tablePagination.queryParams,
      ...tableSort.queryParams,
      ...filters,
    })

  const { data: rooms, isFetching: isRoomsFetching } = useGetRooms({
    limit: 500,
    'category.some': ROOMS_LIMITATIONS.TOURNAMENTS,
    sort: defaultSortString,
  })

  const isFetching = isRoomsFetching || isTournamentsFetching

  const handleFilterConfirm: MUIDataTableOptions['onFilterConfirm'] =
    filterList => {
      const [, room, buyIn, name, dateTimeFilter] = filterList

      const startTimestampEqual =
        dateTimeFilter?.[0]?.length > 0
          ? new Date(dateTimeFilter[0]).toISOString()
          : undefined

      tablePagination.helpers.resetPage()
      setFilters({
        'startTimestamp.from':
          ONLY_FRESH_TOURNAMENTS_START_TIMESTAMP_FROM_DEFAULT.toISOString(),
        'startTimestamp.equal': startTimestampEqual,
        'buyIn.equal': buyIn?.[0]?.length > 0 ? +buyIn[0] : undefined,
        'name.like': name?.[0] ? name[0] : undefined,
        'room.in': room ? (room || []).map(Number) : undefined,
      })
    }

  const roomsSelectValues = useMemo<MultipleSelectOption[]>(
    () => roomsToMultipleSelectOptions(rooms ? rooms.data : []),
    [rooms],
  )

  const [data, count] = tournaments
    ? [tournaments.data, tournaments.count]
    : [[], 0]

  return (
    <TableContainer>
      <MUIDataTable
        title={lang.header}
        data={data}
        columns={[
          {
            name: 'id',
            label: 'ID',
            options: {
              filter: false,
            },
          },
          {
            name: 'room.name',
            label: 'Рум',
            options: {
              customBodyRenderLite: idx => {
                const row: ScheduledTournamentSingleRo = data[idx]
                const { room } = row

                const avatar = room?.avatar?.location

                return (
                  <TableColumn minWidth={70} maxWidth={100}>
                    <TableImageTitle title={room.name} src={avatar} />
                  </TableColumn>
                )
              },
              filter: true,
              filterType: 'custom',
              filterOptions: {
                ...createMultipleSelectFilter(roomsSelectValues, {
                  enableAvatar: true,
                }),
                fullWidth: true,
              },
            },
          },
          {
            name: 'buyIn',
            label: 'Бай-ин',
            options: {
              customBodyRender: value => (
                <TableColumn minWidth={30} maxWidth={30}>
                  {value}
                </TableColumn>
              ),
              filterType: 'custom',
              filterOptions: createRangeFilter({
                type: 'number',
                showSingleFilter: true,
              }),
            },
          },
          {
            name: 'name',
            label: 'Название',
            options: {
              filter: true,
              filterType: 'custom',
              filterOptions: createRangeFilter({
                type: 'text',
                showSingleFilter: true,
              }),
              customBodyRender: (value: string) => (
                <TableColumn maxWidth={260}>
                  <Typography>{value}</Typography>
                </TableColumn>
              ),
            },
          },
          {
            name: 'startTimestamp',
            label: 'Дата и время',
            options: {
              customHeadLabelRender: () => 'Дата и время',
              customBodyRender: TableCreatedAt,
              filterType: 'custom',
              filterOptions: createRangeFilter({
                type: 'datetime-local',
                showSingleFilter: true,
              }),
              filterList: [
                filters['startTimestamp.equal']
                  ? isoStringToDatetimeLocal(filters['startTimestamp.equal'])
                  : '',
              ],
            },
          },
          {
            name: '__action__',
            label: ' ',
            options: {
              filter: false,
              sort: false,
              customBodyRender: (_, meta) => {
                const row = data[meta.rowIndex]

                return (
                  <ButtonGroup>
                    <IconButton
                      title="Выбрать турнир"
                      color="primary"
                      onClick={() => onRowClick(row.id)}
                    >
                      <AddTaskIcon />
                    </IconButton>
                  </ButtonGroup>
                )
              },
            },
          },
        ]}
        options={{
          ...tableDefault.options,
          ...tablePagination.options,
          ...tableSort.options,
          count,
          tableBodyHeight: '500px',
          filter: true,
          onFilterConfirm: handleFilterConfirm,
          textLabels: createTextLabels({
            isLoading: isFetching,
            textLabels: {
              body: {
                noMatch: lang.notFound,
              },
            },
          }),
        }}
        components={{
          TableFilterList: () => null,
        }}
      />
    </TableContainer>
  )
}
