import { memo } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import { Button } from '@mui/material'

import { NavigationItem } from './DashboardNavigation'
import DashboardVerticalNavigation from './DashboardVerticalNavigation'

const DashboardMenuCropped = ({ routes }: { routes: NavigationItem[] }) => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUser()

  if (!currentUser) {
    return null
  }
  return (
    <>
      {routes.slice(0, 3).map(({ key, label, icon, route }) => {
        const href = typeof route === 'function' ? route(currentUser) : route

        return (
          <Button
            component={Link}
            to={href}
            onClick={() => {
              navigate(href)
            }}
            key={key}
            variant="text"
            startIcon={icon}
            sx={{ color: 'text.primary', cursor: 'pointer' }}
          >
            {label}
          </Button>
        )
      })}

      <DashboardVerticalNavigation
        options={routes
          .slice(3, routes.length - 1)
          .map(({ key, icon, label, route }) => {
            const href =
              typeof route === 'function' ? route(currentUser) : route

            return {
              value: key,
              label: (
                <Button
                  component={Link}
                  to={href}
                  onClick={() => {
                    navigate(href)
                  }}
                  key={key}
                  variant="text"
                  startIcon={icon}
                  sx={{ color: 'text.primary', cursor: 'pointer' }}
                >
                  {label}
                </Button>
              ),
            }
          })}
      />
    </>
  )
}

export default memo(DashboardMenuCropped)
