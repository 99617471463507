import { useState } from 'react'
import { Link } from 'react-router-dom'

import { CurrencyRate } from '@/components/Containers/CurrencyRate/CurrencyRate.tsx'
import { Logo } from '@/components/UI/Logo/Logo'
import { ThemeSwitcher } from '@/components/UI/ThemeSwitcher/ThemeSwitcher.tsx'
import { useTheme } from '@/context/ThemeContext.tsx'
import { DashboardNavigation } from '@/layouts/DashboardLayout/DashboardNavigation'
import { DashboardUser } from '@/layouts/DashboardLayout/DashboardUser'
import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  useMediaQuery,
} from '@mui/material'

import DashboardDrawer from './DashboardDrawerMenu'

export const DashboardHeader = () => {
  const [toggle, setToggle] = useState(false)
  const { theme } = useTheme() ?? {}
  // @ts-ignore
  const lg = useMediaQuery(theme => theme.breakpoints.down('lg'))
  // @ts-ignore
  const sm = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const handleToggle = (flag: boolean) => setToggle(flag)

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={
        theme === 'light'
          ? {
              backgroundColor: '#fff',
              color: 'text.primary',
              ...(lg && { overflowX: 'auto' }),
            }
          : { color: 'text.primary', ...(lg && { overflowX: 'auto' }) }
      }
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box mr={4} sx={{ display: 'flex', alignItems: 'center' }}>
              {sm && (
                <Box>
                  <IconButton
                    aria-label="menu"
                    onClick={() => handleToggle(true)}
                    sx={{ marginLeft: '-8px', marginTop: '-4px' }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
              )}
              <Link to="/cabinet">
                <Logo width={sm ? 100 : 180} height={sm ? 20.7 : 40} />
              </Link>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <DashboardNavigation />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 0,
                ml: 'auto',
                alignItems: 'center',
              }}
            >
              {!sm && (
                <Box sx={{ marginRight: { xs: 1, sm: 0 } }}>
                  <ThemeSwitcher />
                </Box>
              )}
              <CurrencyRate from="EUR" to="USD" view="sign-only" />

              <DashboardUser />
            </Box>
          </Box>
        </Toolbar>
      </Container>
      <Divider />

      <Drawer
        open={toggle}
        onClose={() => handleToggle(false)}
        sx={{ maxWidth: '50%' }}
      >
        <Box py={1} px={3}>
          <Link to="/cabinet">
            <Logo width={120} height={26.7} />
          </Link>
        </Box>
        <Divider />
        <Box sx={{ px: 3 }}>
          <ThemeSwitcher type="select" />
        </Box>
        <Divider />
        <DashboardDrawer />
      </Drawer>
    </AppBar>
  )
}
