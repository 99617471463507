import React from 'react'

import { Box, Theme, useMediaQuery } from '@mui/material'

type TableContainerProps = {
  children?: React.ReactNode
}

const TableContainer = ({ children }: TableContainerProps) => {
  return <Box>{children}</Box>
}

export default TableContainer
