import {
  ConfirmModal,
  ConfirmModalProps,
} from '@/components/Modals/ConfirmModal/ConfirmModal'
import { UserReportModal } from '@/components/Modals/UserReportModal/UserReportModal'
import { UserReportScreenshotsModal } from '@/components/Modals/UserReportScreenshotsModal/UserReportScreenshotsModal'
import { TableColumn } from '@/components/Tables/columns/TableColumn/TableColumn'
import { TableCreatedAt } from '@/components/Tables/columns/TableCreatedAt/TableCreatedAt'
import { TableUser } from '@/components/Tables/columns/TableUser/TableUser'
import TableContainer from '@/components/Tables/components/TableContainer'
import { useMenu } from '@/components/Tables/hooks/useMenu'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels'
import { AddEntityButton } from '@/components/Tables/toolbar/AddEntityButton'
import {
  USER_REPORT_BB_100_MONTH_FIELD,
  USER_REPORT_EVBB_100_100K_HANDS_FIELD,
  USER_REPORT_EVBB_100_100K_HANDS_IN_LATE_FIELD,
  USER_REPORT_EVBB_100_100K_HANDS_WITH_FILTER_FIELD,
  USER_REPORT_EVBB_100_MONTH_FIELD,
  USER_REPORT_EVBB_100_MONTH_WITH_FILTER_FIELD,
  USER_REPORT_HANDS_COUNT_FIELD,
  userReportFieldMap,
} from '@/constants'
import { useSnackBar } from '@/context/SnackbarContext'
import { UserReportSingle } from '@/services/data-contracts'
import { useDeleteUserReport } from '@/services/hooks/user-reports/useDeleteUserReport'
import { useGetUserReports } from '@/services/hooks/user-reports/useGetUserReports'
import { Typography } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { ShowFnOutput, useModal } from 'mui-modal-provider'

export interface UserReportsTableProps {
  userId: number
  canAdd: boolean
  canEdit: boolean
  canDelete: boolean
  tableBodyHeight?: string
}

export const UserReportsTable = ({
  userId,
  canAdd,
  canEdit,
  canDelete,
  tableBodyHeight,
}: UserReportsTableProps) => {
  const tableDefault = useTableDefaultOptions()
  const tablePagination = useTablePagination()

  const { showModal } = useModal()
  const { showSnackBar } = useSnackBar()

  const { data: response, isLoading } = useGetUserReports({
    ...tablePagination.queryParams,
    'createdBy.equal': +userId,
  })

  const deleteUserReport = useDeleteUserReport({
    onSuccess: () => showSnackBar('Отчет успешно удален', 'success'),
    onError: () => showSnackBar('Ошибка при удалении отчета', 'error'),
  })

  const [data, count] = response ? [response.data, response.count] : [[], 0]

  const handleOpenUserReportCreateModal = () => {
    const modal = showModal(UserReportModal, {
      mode: 'create',
      reportId: undefined,
      onSuccess: () => {
        showSnackBar('Отчёт успешно создан', 'success')
        modal.hide()
      },
      onError: () => {
        showSnackBar('Ошибка при создании отчёта', 'error')
      },
    })
  }

  const handleOpenUserReportEditModal = (row: UserReportSingle) => {
    const modal = showModal(UserReportModal, {
      mode: 'edit',
      reportId: row.id,
      onSuccess: () => {
        showSnackBar('Отчёт успешно отредактирован', 'success')
        modal.hide()
      },
      onError: () => {
        showSnackBar('Ошибка при редактировании отчёта', 'error')
      },
    })
  }

  const handleOpenUserReportDeleteModal = (row: UserReportSingle) => {
    const modal: ShowFnOutput<ConfirmModalProps> = showModal(ConfirmModal, {
      headerText: `Вы действительно хотите удалить отзыв?`,
      buttons: {
        confirm: {
          onClick: () => {
            deleteUserReport.mutate({ id: row.id })
            modal.hide()
          },
          children: 'Удалить',
        },
        cancel: {
          onClick: () => modal.hide(),
        },
      },
    })
  }

  const handleOpenUserReportScreenshotsModal = (row: UserReportSingle) => {
    showModal(UserReportScreenshotsModal, {
      reportId: row.id,
    })
  }

  const { TableMenuColumn } = useMenu<UserReportSingle>({
    data,
    options: [
      {
        id: 'screenshots',
        label: 'Скриншоты',
        onClick: handleOpenUserReportScreenshotsModal,
      },
      ...(canEdit
        ? [
            {
              id: 'edit',
              label: 'Редактировать',
              onClick: handleOpenUserReportEditModal,
            },
          ]
        : []),
      ...(canDelete
        ? [
            {
              id: 'delete',
              label: 'Удалить',
              onClick: handleOpenUserReportDeleteModal,
            },
          ]
        : []),
    ],
  })

  return (
    <TableContainer>
      <MUIDataTable
        title="Отчёты игрока"
        columns={[
          {
            name: 'createdAt',
            label: 'Создан',
            options: {
              customBodyRender: TableCreatedAt,
            },
          },
          {
            name: 'createdBy.username',
            label: 'Игрок',
            options: {
              customBodyRender: TableUser,
            },
          },
          {
            name: `fields.${USER_REPORT_HANDS_COUNT_FIELD}`,
            label: userReportFieldMap[USER_REPORT_HANDS_COUNT_FIELD],
            options: {
              customBodyRender: (value: string) => (
                <TableColumn width={40}>
                  <Typography variant="body2">{value}</Typography>
                </TableColumn>
              ),
            },
          },
          {
            name: `fields.${USER_REPORT_EVBB_100_MONTH_FIELD}`,
            label: userReportFieldMap[USER_REPORT_EVBB_100_MONTH_FIELD],
            options: {
              customBodyRender: (value: string) => (
                <TableColumn width={40}>
                  <Typography variant="body2">{value}</Typography>
                </TableColumn>
              ),
            },
          },
          {
            name: `fields.${USER_REPORT_BB_100_MONTH_FIELD}`,
            label: userReportFieldMap[USER_REPORT_BB_100_MONTH_FIELD],
            options: {
              customBodyRender: (value: string) => (
                <TableColumn width={40}>
                  <Typography variant="body2">{value}</Typography>
                </TableColumn>
              ),
            },
          },
          {
            name: `fields.${USER_REPORT_EVBB_100_MONTH_WITH_FILTER_FIELD}`,
            label:
              userReportFieldMap[USER_REPORT_EVBB_100_MONTH_WITH_FILTER_FIELD],
            options: {
              customBodyRender: (value: string) => (
                <TableColumn width={40}>
                  <Typography variant="body2">{value}</Typography>
                </TableColumn>
              ),
            },
          },
          {
            name: `fields.${USER_REPORT_EVBB_100_100K_HANDS_FIELD}`,
            label: userReportFieldMap[USER_REPORT_EVBB_100_100K_HANDS_FIELD],
            options: {
              customBodyRender: (value: string) => (
                <TableColumn width={40}>
                  <Typography variant="body2">{value}</Typography>
                </TableColumn>
              ),
            },
          },
          {
            name: `fields.${USER_REPORT_EVBB_100_100K_HANDS_WITH_FILTER_FIELD}`,
            label:
              userReportFieldMap[
                USER_REPORT_EVBB_100_100K_HANDS_WITH_FILTER_FIELD
              ],
            options: {
              customBodyRender: (value: string) => (
                <TableColumn width={40}>
                  <Typography variant="body2">{value}</Typography>
                </TableColumn>
              ),
            },
          },
          {
            name: `fields.${USER_REPORT_EVBB_100_100K_HANDS_IN_LATE_FIELD}`,
            label:
              userReportFieldMap[USER_REPORT_EVBB_100_100K_HANDS_IN_LATE_FIELD],
            options: {
              customBodyRender: (value: string) => (
                <TableColumn width={40}>
                  <Typography variant="body2">{value}</Typography>
                </TableColumn>
              ),
            },
          },
          {
            name: '__actions__',
            label: ' ',
            options: {
              customBodyRenderLite: TableMenuColumn,
            },
          },
        ]}
        data={data}
        options={{
          ...tableDefault.options,
          ...tablePagination.options,
          count,
          responsive: 'standard',
          tableBodyHeight,
          textLabels: createTextLabels({
            isLoading: isLoading,
            textLabels: {
              body: {
                noMatch: 'Отчётов не найдено',
              },
            },
          }),
          ...(canAdd && {
            customToolbar: () => (
              <AddEntityButton onClick={handleOpenUserReportCreateModal} />
            ),
          }),
        }}
        components={tableDefault.components}
      />
    </TableContainer>
  )
}
