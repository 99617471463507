import { useCookies } from 'react-cookie'

import { Auth } from '@/services/api/auth'
import { LoginRo, LoginUserDto } from '@/services/data-contracts'
import { TokensStorage } from '@/services/tokens-storage'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { jwtDecode } from 'jwt-decode'

import { AxiosErrorResponse } from '@/types'

type UseAuthProps = {
  onLoginSuccess?: () => void
  onLoginError?: () => void
  onLogoutSuccess?: () => void
  onLogoutError?: () => void
}

export const useAuth = ({
  onLoginSuccess,
  onLoginError,
  onLogoutSuccess,
  onLogoutError,
}: UseAuthProps = {}) => {
  const queryClient = useQueryClient()
  const [, setCookie, removeToken] = useCookies(['userId'])
  const loginMutation = useMutation<LoginRo, AxiosErrorResponse, LoginUserDto>(
    ['auth', 'login'],
    async (payload: LoginUserDto) => {
      const response = await Auth.login(payload)
      return response.data
    },
    {
      onSuccess: data => {
        const id = jwtDecode(data.accessToken)?.sub
        setCookie('userId', id, {
          path: '/',
          // перенести в env
          domain: 'maxev-portal.pro',
        })
        TokensStorage.set({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        })
        onLoginSuccess?.()
      },
      onError: onLoginError,
    },
  )

  const logoutMutation = useMutation(
    ['auth', 'logout'],
    async () => {
      const response = await Auth.logout()
      removeToken('userId')
      return response.data
    },
    {
      onSuccess: onLogoutSuccess,
      onError: onLogoutError,
      onSettled: async () => {
        TokensStorage.clear()
        await queryClient.clear()
        await queryClient.invalidateQueries()
      },
    },
  )

  return {
    loginMutation,
    logoutMutation,
  }
}
