import {
  ConfirmModal,
  ConfirmModalProps,
} from '@/components/Modals/ConfirmModal/ConfirmModal'
import { RoomModalProps } from '@/components/Modals/RoomModal/RoomModal'
import { RoomNetworkModal } from '@/components/Modals/RoomNetworkModal/RoomNetworkModal.tsx'
import { TableMultipleChip } from '@/components/Tables/columns/TableMultipleChip/TableMultipleChip.tsx'
import TableContainer from '@/components/Tables/components/TableContainer'
import { useMenu } from '@/components/Tables/hooks/useMenu'
import { useTableDefaultOptions } from '@/components/Tables/hooks/useTableDefaultOptions.ts'
import { useTablePagination } from '@/components/Tables/hooks/useTablePagination.tsx'
import { useTableSort } from '@/components/Tables/hooks/useTableSort.ts'
import { createTextLabels } from '@/components/Tables/lang/createTextLabels'
import { AddEntityButton } from '@/components/Tables/toolbar/AddEntityButton'
import { useSnackBar } from '@/context/SnackbarContext'
import { RoomNetworkSingleRo } from '@/services/data-contracts'
import { useDeleteRoomNetwork } from '@/services/hooks/room-networks/useDeleteRoomNetwork.ts'
import { useGetRoomNetworks } from '@/services/hooks/room-networks/useGetRoomNetworks.ts'
import { stringToColor } from '@/utils/stringToColor.ts'
import { Theme, useMediaQuery } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { ShowFnOutput, useModal } from 'mui-modal-provider'

export const RoomNetworksTable = () => {
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const tableDefault = useTableDefaultOptions()
  const tablePagination = useTablePagination()
  const tableSort = useTableSort(['name', 'ASC'])

  const { showSnackBar } = useSnackBar()
  const { showModal } = useModal()

  const { data: rooms, isFetching: isRoomsFetching } = useGetRoomNetworks({
    ...tablePagination.queryParams,
    ...tableSort.queryParams,
  })

  const [data, count] = rooms ? [rooms.data, rooms.count] : [[], 0]

  const deleteRoomNetwork = useDeleteRoomNetwork({
    onSuccess: () => showSnackBar('Сеть румов успешно удалена', 'success'),
    onError: err =>
      showSnackBar('Ошибка при удалении сети румов', 'error', err),
  })

  const handleCreateRoomNetwork = () => {
    const modal: ShowFnOutput<RoomModalProps> = showModal(RoomNetworkModal, {
      mode: 'create',
      roomId: undefined,
      onSuccess: () => {
        showSnackBar('Сеть румов успешно создана', 'success')
        modal.hide()
      },
      onError: err =>
        showSnackBar('Ошибка при создании сети румов', 'error', err),
    })
  }

  const handleEditRoomNetwork = (row: RoomNetworkSingleRo) => {
    const modal: ShowFnOutput<RoomModalProps> = showModal(RoomNetworkModal, {
      roomId: row.id,
      mode: 'edit',
      onSuccess: () => {
        showSnackBar('Сеть румов успешно обновлена', 'success')
        modal.hide()
      },
      onError: err =>
        showSnackBar('Ошибка при обновлении сети румов', 'error', err),
    })
  }

  const handleDeleteRoomNetwork = (row: RoomNetworkSingleRo) => {
    const modal: ShowFnOutput<ConfirmModalProps> = showModal(ConfirmModal, {
      headerText: `Вы действительно хотите удалить сеть румов ${row.name}?`,
      buttons: {
        confirm: {
          onClick: () => {
            deleteRoomNetwork.mutate({ id: row.id })
            modal.hide()
          },
          children: 'Удалить',
        },
        cancel: {
          onClick: () => modal.hide(),
        },
      },
    })
  }

  const { TableMenuColumn } = useMenu<RoomNetworkSingleRo>({
    data,
    options: [
      { id: 'edit', label: 'Редактировать', onClick: handleEditRoomNetwork },
      { id: 'delete', label: 'Удалить', onClick: handleDeleteRoomNetwork },
    ],
  })

  return (
    <TableContainer>
      <MUIDataTable
        title="Сети румов"
        data={data}
        columns={[
          {
            name: 'id',
            label: 'ID',
          },
          {
            name: 'name',
            label: 'Название',
          },
          {
            name: 'rooms',
            label: 'Привязанные румы',
            options: {
              sort: false,
              customBodyRender: (rooms: RoomNetworkSingleRo['rooms']) => {
                if (!rooms.length) {
                  return 'Ни один рум не привязан'
                }

                return (
                  <TableMultipleChip
                    tableColumnProps={{ width: md ? 300 : 400 }}
                    items={rooms.map(room => {
                      const label = room.name

                      return {
                        id: room.id,
                        label: label,
                        avatar: {
                          title: room.name.toUpperCase(),
                          src: room?.avatar?.location,
                          color: stringToColor(label),
                        },
                      }
                    })}
                  />
                )
              },
            },
          },
          {
            name: '__actions__',
            label: ' ',
            options: {
              customBodyRenderLite: TableMenuColumn,
            },
          },
        ]}
        options={{
          ...tableDefault.options,
          ...tablePagination.options,
          ...tableSort.options,
          count,
          tableBodyHeight: '700px',
          textLabels: createTextLabels({
            isLoading: isRoomsFetching,
            textLabels: {
              body: {
                noMatch: 'Сетей румов не найдено',
              },
            },
          }),
          customToolbar: () => (
            <AddEntityButton onClick={handleCreateRoomNetwork} />
          ),
        }}
        components={tableDefault.components}
      />
    </TableContainer>
  )
}
